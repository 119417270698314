import { patchObjectsByObjectId, SUB_EVENT_TYPES } from '@/provider/utils';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import itemsSchema from '../api/forwarding-rule-items-list.graphql';
import { abcSort } from '@/compositions/sortBy';
import ForwardingRuleListItem from '@/modules/connections/ui/connection-card/forwarding-rules/ForwardingRuleListItem.vue';
import { useForwardingRulesMenu } from '@/modules/connections/compositions/forwarding-rules-menu';

export function useForwardingRuleItems({ objectId }) {
  const { subscribeToMore, result, load, restart } = useLazyQuery(
    itemsSchema.load,
    {
      objectId: objectId
    },
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only'
    }
  );

  const getItemActions = forwardingRule => {
    return useForwardingRulesMenu({ forwardingRule, connectionId: objectId });
  };

  const genForwardingRuleListItem = item => {
    return {
      type: ForwardingRuleListItem,
      props: {
        item,
        actions: getItemActions(item)
      }
    };
  };

  const list = useResult(result, [], data => {
    return [...data.objectsToObjects]
      ?.sort((a, b) => abcSort(a.object2.name, b.object2.name))
      .map(i => genForwardingRuleListItem(i.object2));
  });

  subscribeToMore({
    document: itemsSchema.listenForwaringRuleList,
    variables: {
      objectId: objectId
    },
    updateQuery: (previousResult, { subscriptionData }) => {
      console.log('forwarding rule items list subscription');
      const relatedNode = subscriptionData.data?.Objects?.relatedNode;
      const eventType = subscriptionData.data?.Objects?.event;
      if (eventType !== SUB_EVENT_TYPES.insert) return;
      if (!relatedNode) return;
      switch (relatedNode.__typename) {
        case 'ObjectsToObject': {
          return {
            objectsToObjects: [...previousResult.objectsToObjects, relatedNode]
          };
        }
        case 'ObjectProperty': {
          const { objectId } = relatedNode;
          const patchedObjects = patchObjectsByObjectId(
            objectId,
            'objectProperties',
            relatedNode,
            previousResult.objects
          );

          if (patchedObjects) {
            return {
              objects: patchedObjects
            };
          }
        }
      }
    }
  });

  return {
    load,
    list,
    restart
  };
}
