import { useCopy } from '@/compositions/copy';
import { usePopup } from '@/compositions/popup';
import {
  genMenuItemAccess,
  genMenuItemCopy,
  genMenuItemDelete,
  genMenuItemDisable,
  genMenuItemEdit,
  genMenuItemEnable
} from '@/utils/list-generators';
import { objectService } from '@/modules/common/api';

export function useForwardingRulesMenu({ forwardingRule, connectionId }) {
  const popup = usePopup();
  const { copyWithAlert } = useCopy();
  const { id, enabled } = forwardingRule;

  return [
    genMenuItemEdit(() => {
      popup.open({
        component: () =>
          import(
            '@/modules/connections/ui/connection-card/forwarding-rules/ForwardingRuleEdit.vue'
          ),
        props: {
          connectionId,
          forwardingRuleId: id
        }
      });
    }),
    genMenuItemAccess(
      () => {
        popup.open({
          component: () => import('@/components/popup/EditRightsGroup.vue'),
          props: {
            objectId: id
          }
        });
      },
      {
        disabled: true
      }
    ),
    enabled
      ? genMenuItemDisable(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Disable object?',
              onConfirm: () => objectService.disable(id)
            }
          });
        })
      : genMenuItemEnable(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Enable object?',
              onConfirm: () => objectService.enable(id)
            }
          });
        }),
    genMenuItemCopy(async () => copyWithAlert(id), {
      title: 'Copy UUID'
    }),
    genMenuItemDelete(() => {
      popup.openConfirm(
        {
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Delete object?',
            onConfirm: async () => {
              await objectService.delete(id);
            }
          }
        },
        {
          disabled: true
        }
      );
    })
  ];
}
