<template>
  <base-list :items="items" />
</template>

<script>
import { computed, inject, onMounted } from '@vue/composition-api';
import { useForwardingRuleItems } from '@/modules/connections/compositions/forwarding-rule-items';
import { genAddItem } from '@/utils/list-generators';
import { usePopup } from '@/compositions/popup';

export default {
  name: 'ConnectionCardForwardingRules',
  setup() {
    const entityId = inject('entityId');
    const { load, list } = useForwardingRuleItems({ objectId: entityId });
    const popup = usePopup();

    onMounted(() => {
      load();
    });

    const addRule = async () => {
      popup.open({
        component: () => import('./ForwardingRuleEdit.vue'),
        props: {
          connectionId: entityId
        }
      });
    };

    const items = computed(() => {
      return [
        ...list.value,
        genAddItem(
          { title: 'Add' },
          {
            click: addRule
          }
        )
      ];
    });

    return {
      items
    };
  }
};
</script>

<style></style>
