<template>
  <v-hover v-slot:default="{ hover }">
    <v-list-item v-bind="$attrs" exact @click="$emit('click')">
      <v-list-item-avatar :color="colors['default']">
        <v-img
          :src="getObjectImageUrlOrDefault()"
          width="30"
          height="30"
          max-width="30"
          max-height="30"
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
        <v-list-item-subtitle class="d-flex align-center">
          <div class="mr-2" :class="{ 'red--text': !item.enabled }">
            {{ item.enabled ? 'Enabled' : 'Disabled' }}
          </div>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-show="actions.length > 0 && hover">
        <dropdown-menu :items="actions" item-key-text="name">
          <template #activator="{on}">
            <v-btn icon @click.stop.prevent="on.click" @mousedown.stop="">
              <v-icon v-text="'$more_vert'" />
            </v-btn>
          </template>
        </dropdown-menu>
      </v-list-item-action>
    </v-list-item>
  </v-hover>
</template>

<script>
import { firstLetters } from '@/utils';
import { getObjectImageUrlOrDefault } from '@/provider/utils';
import { colors } from '@/compositions/map/utils/data';

export default {
  name: 'ForwardingRuleListItem',
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    actions: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    return {
      firstLetters,
      getObjectImageUrlOrDefault,
      colors
    };
  }
};
</script>

<style></style>
